<template>
  <div class="columns is-marginless is-multiline">
    <div class="column is-12 is-8-widescreen">
      <div class="columns is-multiline">
        <div class="column is-12">
          <card>
            <template slot="header">
              <p class="card-header-title">Subscriptions</p>
            </template>

            <template slot="body">
              <contracts-table-observed :user-id="userId" />
            </template>
          </card>
        </div>

        <div class="column is-12">
          <account-invoices :user-id="userId" />
        </div>
      </div>
    </div>

    <div class="column is-12 is-4-widescreen">
      <div class="columns is-multiline">
        <div class="column is-12">
          <card>
            <template slot="header">
              <p class="card-header-title">Payment methods</p>
            </template>

            <template slot="body">
              <account-credit :user-id="userId" />
              <payment-methods :user-id="userId" context="manage" />
            </template>
          </card>
        </div>

        <div class="column is-12">
          <div class="box">
            <currency-field :user-id="userId" />
            <company-details-field :user-id="userId" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Billing",
  components: {
    "currency-field": () => import("@shared/account/_currencyField"),
    "account-credit": () => import("@shared/account/_accountCredit"),
    "payment-methods": () => import("@shared/account/_paymentMethods"),
    "account-invoices": () => import("./_invoices"),
    "contracts-table-observed": () =>
      import("@shared/contracts/_contractsTableObserved"),
    "company-details-field": () =>
      import("@shared/account/_companyDetailsField")
  },
  computed: {
    userId() {
      return this.$store.getters["auth/userId"]();
    }
  }
};
</script>
